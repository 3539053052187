import React from 'react'

import Context from '../components/Store/Context'
import Member from '../components/Member'
import { MM_EXTENSION_AUTHORIZED, loginURLkey, getAuthStringFromBrowser, getAuthObject } from '../components/Store/auth'
import { deleteReq } from './ConnectMetamask/helpers/api'
import CopyField from '../components/CopyField'
import { MemberItem } from '../components/Store/Types/models/User'
import { isWebApp, isInApp } from '../utils/detectBrowserType'


const Profile = () => {
  const { user, fetchUser, setIsAuthorized, wallet, isAuthorized } = React.useContext(Context)
  const authObj = getAuthObject()
  const disconnectTelegram = async () => {
    window.localStorage.removeItem(loginURLkey)
    await fetchUser()
  }
  const disconnectMetamsk = async () => {
    window.localStorage.setItem(MM_EXTENSION_AUTHORIZED, 'false')
    await deleteReq('/walletConnectSessions')
    setIsAuthorized(false)
  }

  return (
    <div className='container'>
      {authObj &&
        <Member
          user={authObj as MemberItem}
          className='mb-10 me-0'
        />
      }
      {user &&
        <CopyField label="Your address">
          {user.address}
        </CopyField>
      }
      {(!isWebApp() && !isInApp() && getAuthStringFromBrowser()) &&
        <div className="my-20 green-link" onClick={disconnectTelegram}>
          Disconnect Telegram from browser
        </div>
      }
      {(!isWebApp() && window.localStorage.getItem(MM_EXTENSION_AUTHORIZED)) &&
        <div className="my-20 green-link" onClick={disconnectMetamsk}>
          Disconnect Metamask extension
        </div>
      }
      {(isAuthorized && wallet) &&
        <p>Connected: {wallet.type}</p>
      }
    </div>
  )
}


export default Profile
