import toast from 'react-hot-toast'

import { User } from './Types/models/User'
import { get } from '../../pages/ConnectMetamask/helpers/api'
import compareAddresses from '../../utils/compareAddresses'
import { isInApp, isMobile, isWebApp } from '../../utils/detectBrowserType'


const getAuthStringFromWebApp = () => window.Telegram.WebApp.initData

export type WebAppAuthObject = {
  id?: string
  first_name?: string
  last_name?: string
  username?: string
  hash?: string
  [key: string]: string | undefined
}
const getWebAppAuthObject = (): WebAppAuthObject | false =>
  isWebApp() &&
  JSON.parse(
    Object.fromEntries(new URLSearchParams(decodeURIComponent(getAuthStringFromWebApp())))
      .user
  )

const loginURLkey = 'Telegram.LoginURL.initData'
const MM_EXTENSION_AUTHORIZED = 'MM_EXTENSION_AUTHORIZED'

const getAuthStringFromBrowser = () => {
  const authString = window.localStorage.getItem(loginURLkey)

  return !authString ? undefined : authString
}

export type BrowserAuthObject = {
  id?: string
  first_name?: string
  last_name?: string
  username?: string
  hash?: string
  [key: string]: string | undefined
}
const getBrowserAuthObject = (): BrowserAuthObject | undefined =>
  getAuthStringFromBrowser() ?
    Object.fromEntries(new URLSearchParams(getAuthStringFromBrowser()))
    :
    undefined

const hideAuthStringFromLoginURL = () => {
  const userFromSearchParams = new URLSearchParams(window.location.search)

  if (!userFromSearchParams.has('id')) {
    return
  }

  const groupId = userFromSearchParams.get('groupId')

  if (groupId) {
    window.localStorage.setItem('groupId', `${groupId}`)
    userFromSearchParams.delete('groupId')
  }

  window.localStorage.setItem(loginURLkey, userFromSearchParams.toString())

  if (!isInApp()) {
    // setSearchParams + navigate() doesn't erase searchParams if they are before /#/
    // Telegram's LoginURL always places searchParams before /#/
    window.location.replace(window.location.pathname + '/' + window.location.hash)
    // console.log(window.location)
  }
}

const getAuthObject = () => getWebAppAuthObject() || getBrowserAuthObject()

async function getAddressFromMetamaskExtension(): Promise<string> {
  if (isWebApp() || !window.ethereum) return ''

  try {
    const accounts = await window.ethereum?.request({ method: 'eth_requestAccounts' })
    return accounts?.[0] || ''
  } catch (error: any) {
    if (error?.code === -32002) {
      toast.error('Please, open your Metamask extension')
    }
    throw error
  }
}

const getUser = async () => {
  hideAuthStringFromLoginURL()
  
  const authObject = getAuthObject()

  if (!authObject) return null
  try {
    const { data: user } = await get<User>(`/users/${authObject.id}`)

    if (!user) return null

    return user
  } catch (error: any) {
    toast.error(error?.response?.data?.message || error?.message)
    return null
  }
}

const getAuthHeader = () => ({
  headers: {
    telegramData: encodeURIComponent(getAuthStringFromWebApp() || getAuthStringFromBrowser())
  }
})

async function getWalletConnectConnection(userId: any) {
  if (!userId) return []

  const { data } = await get<any>(`/walletConnectSessions/?telegramUserId=${userId}`)
  return data.accounts
}

async function checkAuth() {
  const user = await getUser()
  const params = getAuthObject()

  if (!user) {
    throw new Error('Not authorised')
  }

  // DESKTOP + MM extension
  if (!isMobile() && window.ethereum) {
    let address

    const MMisAuthorised = window.localStorage.getItem(MM_EXTENSION_AUTHORIZED)

    if (!MMisAuthorised || MMisAuthorised === 'false') {
      throw new Error('The account is disconnected')
    }

    try {
      ;[address] = await getWalletConnectConnection(params?.id)
    } catch (error) {
      address = window.ethereum?._state?.accounts?.[0]
    }

    if (!address || compareAddresses(user.address, address)) {
      throw new Error('Not authorised')
    }

    console.log('[auth] is Authorized with addr', address)
    return address
  }

  // MOBILE
  // or
  // DESKTOP in telegram (e.g. private msg with bot)
  if (isMobile() || isWebApp()) {
    if (!params?.id) {
      throw new Error('user ID is not provided')
    }

    const accounts = await getWalletConnectConnection(params?.id)

    if (!accounts) {
      throw new Error('Not authorised')
    }

    return accounts[0]
  }

  throw new Error('Not authorised')
}


export {
  getAuthStringFromWebApp,
  getWebAppAuthObject,
  loginURLkey,
  MM_EXTENSION_AUTHORIZED,
  getAuthStringFromBrowser,
  getBrowserAuthObject,
  getAuthObject,
  getAddressFromMetamaskExtension,
  getUser,
  getAuthHeader,
  isWebApp,
  checkAuth
}
