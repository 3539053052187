import React from 'react'
import { User } from './models/User'


export type StateType = {
  setIsAuthorized: (b: boolean) => void
  isAuthorized: boolean
  ready: boolean
  popup?: OpenPopupProps
  user?: User | null
  wallet: any
  pageToReturnAfterLogin?: string
  threshold: number // used in CreateDAO page. Should be in global state, to be protected from page reloads
}
const initialState: StateType = {
  setIsAuthorized: () => false,
  isAuthorized: false,
  ready: false,
  wallet: null,
  threshold: 1
}

export type OpenPopupProps = {
  text?: string | React.ReactNode
  button?: {
    label?: string | React.ReactNode
    color?: string
    onClick?: () => void
  }
  showLoader?: boolean
}

export type NonStateType = {
  isAuthorized: boolean
  setIsAuthorized: (b: boolean) => void
  setState: (obj: Partial<StateType>) => void
  registerInitializeCallback: (fn: () => void) => void
  fetchUser: () => Promise<void>
  openPopup: (props: OpenPopupProps) => void
  closePopup: () => void
  updatePopup: (props: OpenPopupProps) => void
  wallet: any
  playAudio: (type?: 'button' | 'failed') => void
}
const initialNonState: NonStateType = {
  setIsAuthorized: () => false,
  isAuthorized: false,
  setState: () => {},
  registerInitializeCallback: () => {},
  fetchUser: async () => {},
  openPopup: () => {},
  closePopup: () => {},
  updatePopup: () => {},
  wallet: {},
  playAudio: () => {}
}


export { initialState, initialNonState }
