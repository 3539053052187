import React, { useEffect, useState } from 'react'

import { useNavigate } from 'react-router-dom'
import { StateType, initialState, NonStateType, OpenPopupProps } from './Types'
import Context from './Context'
import { checkAuth, getUser } from './auth'
import useWallet from '../../utils/wallet/useWallet'

import buttonSound from '../../styles/sound/button-sound.m4a'
import failedSound from '../../styles/sound/failed-sound.m4a'


type PropsType = {
  children: any
}


const Provider: React.FC<PropsType> = ({ children }) => {
  const [state, _setState] = useState<StateType>(initialState)
  const setState = (someParams: Partial<StateType>) =>
    _setState({
      ...state,
      ...someParams
    })
  const navigate = useNavigate()
  const initializeCallBacks: Function[] = []
  const [isAuthorized, setIsAuthorized] = useState(false)
  const wallet = useWallet(isAuthorized)
  
  const buttonSoundInstance = React.useRef(new Audio(buttonSound))
  const failedSoundInstance = React.useRef(new Audio(failedSound))
  const playAudio = (type: 'button' | 'failed' = 'button') => {
    return
    switch(type) {
      case 'button':
        buttonSoundInstance.current.currentTime = 0
        buttonSoundInstance.current.play()
        break
      case 'failed':
        failedSoundInstance.current.currentTime = 0
        failedSoundInstance.current.play()
        break
    }
  }

  const checkAuthorization = async (): Promise<boolean> => {
    let isAuth
    try {
      await checkAuth()
      isAuth = true
    } catch (error: any) {
      isAuth = false
    }

    setIsAuthorized(isAuth)
    return isAuth
  }

  const initialization = async () => {
    if (!state.ready) {
      await checkAuthorization()

      // window.Telegram.WebApp.setBackgroundColor('#0E0E0E')
      window.Telegram.WebApp.expand()
      window.Telegram.WebApp.BackButton.onClick(() => {
        if (state.popup?.text) {
          if (state.popup?.button?.onClick) {
            state.popup.button.onClick()
          } else {
            stateAndSetters().closePopup()
          }
        } else {
          navigate(-1)
        }

        if (window.history.length < 3) window.Telegram.WebApp.BackButton.hide()
      })

      await fetchUser()
      callInitializeCallbacks()
    }
  }

  const fetchUser = async () => {
    setState({ ready: false })
    const fetchedUser = await getUser()
    setState({
      user: fetchedUser,
      ready: true
    })
  }

  useEffect(() => {
    initialization()
  }, [])

  const registerInitializeCallback = (fn: Function) => {
    initializeCallBacks.push(fn)
    state.ready && fn()
  }

  const callInitializeCallbacks = () =>
    setTimeout(() => initializeCallBacks.forEach((callback) => callback()), 50)

  const stateAndSetters = () => {
    const nonState: NonStateType = {
      isAuthorized,
      setIsAuthorized,
      wallet,
      setState,
      registerInitializeCallback,
      fetchUser,
      openPopup: (props: OpenPopupProps) =>
        setState({
          popup: props
        }),
      closePopup: () =>
        setState({
          popup: undefined
        }),
      updatePopup: (props: OpenPopupProps) =>
        setState({
          popup: props
        }),
      playAudio
    }

    return {
      ...(state.ready ? state : initialState),
      ...nonState
    }
  }

  return <Context.Provider value={stateAndSetters()}>{children}</Context.Provider>
}

export default Provider
