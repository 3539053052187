import React, { useEffect } from 'react'

import { useNavigate, useParams } from 'react-router-dom'
import toast from 'react-hot-toast'

import { Context, useQuery } from '../components/Store'
import Title from '../components/Title'
import { Group, GROUP_STATE } from '../components/Store/Types/models/Group'
import UserSearch, { SEARCH_SCOPE } from '../components/UserSearch'
import MembersList from '../components/MembersList'
import EnterThreshold from '../components/EnterThreshold'
import Button from '../components/Button'
import { User } from '../components/Store/Types/models/User'
import { post, put } from './ConnectMetamask/helpers/api'
import Link from '../components/Link'
import renderOpenMetamaskNotification from './common/RenderOpenMetamaskNotification'
import useTitle from '../hooks/useTitle'
import { LoadingScreen } from '../components/Loader'


const CreateDAO: React.FC<{
  groupId: number
  refetchGroup: any
  group: Group
  threshold: number
  setThreshold: (value: number) => void
}> = ({
  refetchGroup,
  groupId,
  group,
  threshold,
  setThreshold
}) => {
  const { openPopup, closePopup, updatePopup, wallet } = React.useContext(Context)
  const navigate = useNavigate()

  async function editMember(
    user: Partial<User>,
    route: '/registration' | '/registration/remove'
  ) {
    try {
      await post(route, {
        [user.id ? 'userId' : 'address']: user.id || user.address,
        groupId
      })
      navigate('/refresh')
      setTimeout(() => navigate(-1), 50)
    } catch (error) {
      toast.error('Edit user fails')
    }
  }

  async function createDAO() {
    const updatedGroup: Group = await refetchGroup()

    openPopup({
      text: <p style={{ textAlign: 'center' }}>{renderOpenMetamaskNotification()}</p>,
      button: {
        label: 'Back',
        color: 'pink',
        onClick: () => closePopup()
      }
    })

    await put(`/groups/${groupId}`, { state: GROUP_STATE.DAO_INITIALIZATION })

    try {
      const owners = updatedGroup.users.map((user) => user.address)

      const { data: daoTransaction } = await post(
        `/groups/${groupId}/generateDAOTransaction`,
        {
          owners,
          threshold
        }
      )

      const txHash = await wallet.sendTransaction(daoTransaction)

      updatePopup({
        showLoader: true
      })

      const response = await post('/groups/initDao', {
        groupId,
        txHash,
        owners,
        threshold
      })

      closePopup()

      if (response.status === 201 || response.status === 200) {
        navigate(`/groups/${groupId}`, { replace: true })
      }
    } catch (error: any) {
      closePopup()
      await put(`/groups/${groupId}`, { state: GROUP_STATE.REGISTRATION_IS_OPEN })
      toast.error(error?.response?.data?.message || error?.message)
    }
  }

  return (
    <div className="container d-flex flex-column">
      <Title title={group.title} className="mb-15" />
      <UserSearch
        groupId={groupId}
        onChange={(user) => editMember(user, '/registration')}
        scope={SEARCH_SCOPE.ALL_EXCEPT_DAO}
        label="Add member"
        className="mb-20"
      />
      <div className="d-flex flex-row justify-content-between mb-10">
        <h3 className="h3">Confirmed participants</h3>
        <h3 className="h3">{group?.users?.length}</h3>
      </div>
      <MembersList
        editable
        cantRemoveOwner
        group={group}
        onClick={(user) => editMember(user, '/registration/remove')}
        className="mb-auto"
      />
      <EnterThreshold
        value={threshold}
        onChange={setThreshold}
        min={1}
        max={group?.users?.length || 1}
        className="mb-10"
      />
      <h5 className="h5 mb-55 w-90">
        Set the minimum amount of votes needed to pass a proposal. The voting threshold
        can be updated when new participants are added or removed.
      </h5>
      <div className="my-10">
        <Link
          className="Button Button--green mt-auto justify-content-center"
          to={`/${groupId}/addDAO`}
        >
          Add existing DAO
        </Link>
      </div>
      <Button pink className="mb-10" onClick={createDAO}>
        Create DAO
      </Button>
      <h5 className="h5 w-90">
        Wallet addresses of confirmed participants will open a GnosisSafe multisig to
        initiate DAO.
      </h5>
    </div>
  )
}

type ParamsType = {
  id: string
}

export default () => {
  const { id = '' } = useParams<ParamsType>()
  const { data: group, refetch } = useQuery<Group>(`/groups/${id}`)
  const isLoading = group === null
  // threshold should be lifted to global state. Otherwise its value is reinitialized upon every refetch
  const { threshold, setState } = React.useContext(Context)

  React.useEffect(() => {
    if (group && group?.users?.length < threshold)
      setState({ threshold: group.users.length })
  }, [group])

  useTitle(`Create DAO for ${group?.title}`)

  return isLoading ? (
    <LoadingScreen />
  ) : (
    group && (
      <CreateDAO
        refetchGroup={refetch}
        groupId={parseInt(id, 10)}
        group={group}
        threshold={threshold}
        setThreshold={(value: number) => setState({ threshold: value })}
      />
    )
  )
}
