import React from 'react'

import { format } from 'date-fns'

import Link from './Link'
import Button from './Button'
import MembersIcons from './MembersIcons'

import { GroupPreview } from './Store/Types/models/Group'

type GroupItemProps = {
  group: GroupPreview
  type: 'registered' | 'pending' | 'DAO'
  className?: string
}

const GroupItem: React.FC<GroupItemProps> = ({
  group,
  type,
  className
}) => (
  <Link
    to={`/groups/${group.id}`}
    className={`d-block ${type === 'DAO' ? 'mb-33' : 'mb-10'}`}
  >
    <div className={`GroupItem ${className}`}>
      <MembersIcons
        className={type !== 'DAO' ? 'order-2' : ''}
        users={group.userPreviews || []}
      />
      {type === 'DAO' ?
        <>
          <div className="GroupItem__title mx-15">
            {group.name}
          </div>
          <div className="GroupItem__date">
            {format(new Date(group.createdAt), 'dd.MM.yy')}
          </div>
        </>
        :
        <Button
          pink
          className="GroupItem__button"
          left={type === 'pending' ? 'Initialize' : 'Pending'}
          right={group.name}
        />
      }
    </div>
  </Link>
)

export default GroupItem
