import { isMobile as _isMobile } from 'react-device-detect'
import InApp from 'detect-inapp'

import { getAuthStringFromWebApp } from '../components/Store/auth'


const isMobile = () => _isMobile
const isWebApp = () => !!getAuthStringFromWebApp()
const isInApp = () => {
  const inapp = new InApp(navigator.userAgent || navigator.vendor || window.opera)

  return inapp.isInApp
}

export {
  isMobile,
  isWebApp,
  isInApp
}