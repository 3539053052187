import ConnectMetamask from '../pages/ConnectMetamask'
import ConnectTelegram from '../pages/ConnectTelegram'
import AlreadyAuthorized from '../pages/AlreadyAuthorized'
import Groups from '../pages/Groups'
import Group from '../pages/Group'
import GroupProposals from '../pages/GroupProposals'
import Proposal from '../pages/Proposal'
import NewProposal from '../pages/NewProposal'
import AddRemoveParticipant from '../pages/AddRemoveParticipant'
import { PROPOSAL_TYPES } from '../components/Store/Types/models/Proposal'
import AddDAO from '../pages/AddDAO'
import TransferFunds from '../pages/TransferFunds'
import Profile from '../pages/Profile'

export type Route = {
  to: string
  title?: string
  Comp: any
  exact?: boolean
  unauthorized?: boolean
}

const routes: Route[] = [
  {
    to: '/connect-metamask',
    title: 'Connect Metamask',
    Comp: <ConnectMetamask />
  },
  {
    to: '/connect-telegram',
    title: 'Connect Telegram',
    Comp: <ConnectTelegram />
  },
  {
    to: '/already-authorized',
    title: 'Already authorized',
    Comp: <AlreadyAuthorized />
  },
  {
    to: '/:groupId/addDAO',
    Comp: <AddDAO />
  },
  {
    exact: true,
    to: '/groups',
    title: 'Groups',
    Comp: <Groups />
  },
  {
    to: '/groups/:id',
    Comp: <Group />
  },
  {
    exact: true,
    to: '/proposals/:groupId/:pageNumber',
    Comp: <GroupProposals />
  },
  {
    to: '/proposals/:proposalId',
    Comp: <Proposal />
  },
  {
    exact: true,
    to: '/:groupId/new-proposal',
    Comp: <NewProposal />
  },
  {
    to: '/:groupId/new-proposal/transfer-funds/:tokenSymbol',
    Comp: <TransferFunds />
  },
  {
    to: '/:groupId/new-proposal/transfer-funds',
    Comp: <TransferFunds />
  },
  {
    to: '/:groupId/new-proposal/add-participant',
    Comp: <AddRemoveParticipant type={PROPOSAL_TYPES.ADD_PARTICIPANT} />
  },
  {
    to: '/:groupId/new-proposal/remove-participant',
    Comp: <AddRemoveParticipant type={PROPOSAL_TYPES.REMOVE_PARTICIPANT} />
  },
  {
    to: '/profile',
    Comp: <Profile />
  }
]

export default routes
