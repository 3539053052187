import React from 'react'

import {
  Routes,
  Route,
  Navigate,
  useLocation,
} from 'react-router-dom'

import routes from '../utils/routes'
import { Context } from './Store'
import { getAuthObject } from './Store/auth'
import TitleWrapper from './TitleWrapper'


const ProtectedRoutes: React.FC = () => {
  const {
    setState,
    isAuthorized,
    pageToReturnAfterLogin
  } = React.useContext(Context)
  const { pathname } = useLocation()
  const groupIdFromSearchParams = window.localStorage.getItem('groupId')

  if (groupIdFromSearchParams) {
    // Remove when nin-js-bot-tg link is changed in utils/getLoginURL
    if (pathname === '/') {
      return <Navigate to={`/groups`} />
    }
    if (pathname === '/groups') {
      window.localStorage.removeItem('groupId')

      return <Navigate to={`/groups/${groupIdFromSearchParams}`} />
    }
  }

  let unprotectedRoutes = ['/connect-metamask', '/connect-telegram']
  // if (getAuthObject())
  //   unprotectedRoutes.push('/profile')

  if (!isAuthorized && !unprotectedRoutes.includes(pathname)) {
    setState({ pageToReturnAfterLogin: pathname })
    const authObject = getAuthObject()
    return <Navigate to={authObject ? '/connect-metamask' : '/connect-telegram'} />
  }

  if (isAuthorized) {
    if (pageToReturnAfterLogin) {
      const _pageToReturnAfterLogin = pageToReturnAfterLogin.slice()
      setState({ pageToReturnAfterLogin: undefined })
      if (_pageToReturnAfterLogin !== pathname)
        return <Navigate to={_pageToReturnAfterLogin} />
    }
    if (unprotectedRoutes.includes(pathname)) {
      return <Navigate to={'/'} />
    }
  }

  return (
    <Routes>
      {routes.map((route) => (
        <Route
          key={route.to}
          path={route.to}
          element={
            <TitleWrapper title={route.title}>
              {route.Comp}
            </TitleWrapper>
          }
        />
      ))}
      <Route
        path="*"
        element={
          <Navigate
            to={{
              pathname: '/groups',
              search: window.location.search
            }}
            replace
          />
        }
      />
    </Routes>
  )
}


export default ProtectedRoutes
