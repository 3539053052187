import React from 'react'

import { useLocation, useNavigate } from 'react-router'

import Divider from './Divider'
import Avatar from './Avatar'
import { getAuthObject } from './Store/auth'
import printUsername from '../utils/printUsername'
import LinkWrapper from './Link'
import { User } from './Store/Types/models/User'
import { Context } from './Store'


type HeaderProps = {
  className?: string
}


const Header: React.FC<HeaderProps> = ({ className = '' }) => {
  const userFromBrowser = getAuthObject()
  const navigate = useNavigate()
  const location = useLocation()
  const { playAudio } = React.useContext(Context)

  return (
    <div className={`Header ${className}`}>
      <div className="container">
        {userFromBrowser && (
          <div className="d-flex flex-row">
            {(window.history.length > 2 && location.pathname !== '/groups') &&
              <div
                className='green-link'
                onClick={() => {
                  navigate(-1)
                  playAudio()
                }}
              >
                &#60; Back
              </div>
            }
            {Object.keys(userFromBrowser).includes('id') &&
              <LinkWrapper to='/profile' className='ms-auto green-link'>
                <div className="d-flex flex-row align-items-center font-inherit">
                  {printUsername(userFromBrowser as Partial<User>)}
                  <Avatar img={userFromBrowser.photo_url} className="ms-10 my-5" />
                </div>
              </LinkWrapper>
            }
          </div>
        )}
      </div>
      <Divider />
    </div>
  )
}


export default Header
