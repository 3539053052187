import React from 'react'

import { MemberItem } from './Store/Types/models/User'
import { Context } from './Store'
import Avatar from './Avatar'

import { ReactComponent as Cross } from '../styles/img/cross.svg'


type MemberProps = {
  user: MemberItem
  editable?: boolean
  simple?: boolean
  inline?: boolean
  onClick?: (e: any) => void
  className?: string
}


const Member: React.FC<MemberProps> = ({
  user,
  editable,
  simple,
  inline,
  onClick,
  className
}) => {
  const { playAudio } = React.useContext(Context)

  return (
    <div
      className={`
        Member
        ${simple && 'Member--simple'}
        ${inline && 'Member--inline'}
        ${className}
      `}
    >
      {user.username && (
        <>
          <Avatar img={user.avatar} className="Member__avatar" />
          <div className="Member__username">{user.username}</div>
        </>
      )}
      {!user.username && user.address && (
        <div className="Member__address">{user.address}</div>
      )}
      {editable &&
        <Cross
          onClick={(e: any) => {
            onClick?.(e)
            playAudio('failed')
          }}
          className="Member__cross"
        />}
    </div>
  )
}


export default Member
